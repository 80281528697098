// All api calls are initialized in this component for this application
//remove unwanted and credentials of other app

module.exports = Object.freeze({
  BASE_URL: 'https://react.quickvee.com/ReactCI/',
  // BASE_URL_SANDBOX: 'https://sandbox.quickvee.net/',
  BASE_URL_SANDBOX: 'https://production.quickvee.net/',
  // BASE_URL_SANDBOX: 'https://www.quickvee.net/',

  // Authentication all api calls
  GOOGLE_LOGIN_CUSTOMER: 'CustomerLoginReact/google_callback',
  RESET_CUSTOMER_PASSWORD: 'loginController/reset_password_save',
  FORGET_CUSTOMER_PASSWORD: 'Forget_pass/reset_pass_send',
  CHANGE_PASSWORD: 'Forget_pass/reset_pass_save/',
  // LOGIN_CUSTOMER: 'loginController/login',
  LOGIN_CUSTOMER_NEW: 'CustomerLoginReact/login',
  // REGISTER_CUSTOMER: 'loginController/register',
  REGISTER_CUSTOMER_NEW: 'CustomerLoginReact/register',

  LOGIN_MERCHANT: 'loginController/chk_merchant',
  CHECK_USERNAME_MERCHANT: 'loginController/chk_merchant',

  // Customer Account all api calls
  GET_CUSTOMER_ACCOUNT_INFO: 'Customer_Account/All_customer_account_detail',
  UPDATE_CUSTOMER_BASIC_INFO: 'Customer_Account/Update_customer_Accounts',
  ADD_DELIVERY_ADDRESS: 'Customer_Account/update_delivery_address',
  DELETE_DELIVERY_ADDRESS: 'Customer_Account/destroyDeliveryAddress',
  UPDATE_DELIVERY_ADDRESS: 'Customer_Account/update_delivery_address',
  ACCOUNT_CHANGE_PASSWORD: 'Customer_Account/save_password',
  CROP_ID_IMAGE: 'Findstore/saveIDCardReact',
  UPDATE_BILLING_INFO: 'Customer_Account/update_Accounts_identification',
  SET_DEFAULT_ADDRESS: 'Customer_Account/set_default_addr',

  // Order all api calls
  GET_ALL_OPEN_ORDERS: 'MyOrder_react/open',
  GET_ALL_CLOSE_ORDERS: 'MyOrder_react/close',

  // Payment all api calls

  //Single Store all api calls
  GET_SINGLE_STORE_DATA: 'Findstore/merchant',
  GET_SINGLE_STORE_DATA_NEW: 'FindstoreReact/merchant_store_and_category',
  GET_SINGLE_STORE_PRODUCT_DATA: 'FindstoreReact/product_data',

  // Wishlist all api calls
  GET_WISHLIST_PRODUCTS: 'FindstoreReact/listwishlist',
  ADD_TO_WISHLIST: 'FindstoreReact/savewishlist',
  DELETE_TO_WISHLIST: 'FindstoreReact/deleteWishlistAjax',

  // Single Product all api calls
  GET_SINGLE_PRODUCT: 'Findstore/SingleProduct',
  GET_SINGLE_PRODUCT_NEW: 'FindstoreReact/single_product',
  GET_FEATURED_PRODUCTS: 'FindstoreReact/get_featured_product',
  GET_HIGHEST_SELLING_PRODUCTS: 'FindstoreReact/get_highest_selling',

  // GET ALL VARIENT DATA IN CART
  // GET_VARIENT_DATA: 'Findstore/chk_variant',
  GET_VARIENTS_DETAIL: 'FindstoreReact/getVarientDetails',
  GET_VARIANT_PRODUCT_QUANTITY: 'FindstoreReact/checkValidQuantity',

  // GET ALL STORES DETAIL
  GET_ALL_STORES: 'FindstoreReact/GetAllDefaultsAndStores',

  // GET ALL STATES LIST
  GET_ALL_STATE: 'FindstoreReact/getstatelist',

  // GET HOME PAGE DATA
  GET_HOME_STORES_AND_CATEGORIES: 'home_react',

  GET_SINGLE_CATEGORY_SEARCH_DATA: 'home_react/search',
  UPDATE_PAYMENT_BILLING_INFO: 'Customer_Account/updateBillingAddress',

  // GET ALL STORE CREDIT HISTORY LIST
  GET_ALL_STORE_CREDIT_HISTORY: 'Lp_sc_api/store_credit_history',

  // GET ALL LOYALTY POINT HISTORY LIST
  GET_ALL_LOYALTY_POINT_HISTORY: 'Lp_sc_api/loyalty_program_history',

  // GET_ORDER_SUMMERY_DETAILS:'MyOrder/details',
  GET_ORDER_SUMMERY_DETAILS: 'Orders/order_details',

  //  COUPON DETAIL
  GET_COUPON_DETAIL: 'FindstoreReact/check_coupon',
  GET_ALL_COUPON_LIST: 'FindstoreReact/coupon_list',

  // PAYMENT PAGE
  GET_TAX_DETAIL: 'FindstoreReact/tax_list',
  GET_DATE_TIME: 'FindstoreReact/get_date_times',
  CHECK_ADDRESS_IN_RANGE: 'FindstoreReact/check_address',
  LOGIN_WITH_GUESTS: 'CustomerLoginReact/register',

  // CART PRODUCT API
  GET_ALL_CART_PRODUCT: 'FindstoreReact/getProductById',

  // PAYMENT API
  SAVE_ORDER: 'FindstoreReact/saveOrder',
  GET_ALL_USER_CARDS: 'FindstoreReact/getcardlist',
  DELETE_CARD: 'FindstoreReact/deleteSavedCard',
  LP_SC_PAYMENT: 'FindstoreReact/get_lp_sc_data',
  USED_LP_POINTS: 'FindstoreReact/calculate_loyality_points',

  // BEFORE PLACING ORDER, VALIDATE PRODUCTS
  VALIDATE_PRODUCTS: 'FindstoreReact/verify_cart_quantity',

  // GET MERCHANT STORE PRODUCTS USING SEARCH BAR
  SEARCH_PRODUCTS: 'FindstoreReact/getSearchProduct',
});
